<template>
  <va-card class="d-flex">

    <div class="row align--top">
      <div class="flex xs12 md7">
        <h1>Top Selling Products</h1>

        <!-- Show More Button -->
        <va-button
          small
          slot="actions"
          class="mr-0"
          color="info"
          :disabled="topSellingItems.length >= allProducts.length"
          @click="showMore(1)">
          Show more
        </va-button>

        <va-button
          small
          slot="actions"
          class="mr-0"
          outline
          color="info"
          :disabled="topSellingItems.length <= 5"
          @click="showMore(-1)">
          Show less
        </va-button>
      </div>

      <!-- Product Sales Bars -->
      <div class="flex xs12 md6"
        v-for="(item, index) in topSellingItems"
        :key="index">
        <va-progress-bar
          :value="getPercent(item.totalProductSold)"
          :color="getColors(item.totalProductSold)">
          {{ item.totalProductSold }} sold for €{{ item.totalProductRevenue.toFixed(2) }}
        </va-progress-bar>
        <p class="mt-2" v-on:click="openProduct(item.productID)">{{ short(item.productName) }}</p>
      </div>
    </div>
  </va-card>
</template>

<script>
export default {
  name: 'TopProducts',
  props: ['allProducts'],
  data () {
    return {
      topSellingItems: [],
    }
  },
  methods: {
    getPercent: function (sold) {
      return (sold / this.topSellingItems[0].totalProductSold) * 100
    },

    short: function (value) {
      return value.slice(0, 50)
    },

    showMore: function (step) {
      const difference = this.allProducts.length - this.topSellingItems.length
      const range = this.topSellingItems.length + (((difference < 5 && difference > 0) ? difference : 5) * step)
      this.topSellingItems = this.allProducts.slice(0, range)
    },

    openProduct: function (productID) {
      window.open(
        'https://www.turtlecreek.ie/product/' +
        productID,
        '_blank',
      )
    },

    getColors: function (value) {
      const sold = this.getPercent(value)
      if (sold <= 100 && sold > 75) {
        return 'success'
      } else if (sold <= 75 && sold > 50) {
        return 'secondary'
      } else if (sold <= 50 && sold > 25) {
        return 'info'
      } else {
        return 'warning'
      }
    },
  },
  watch: {
    allProducts: function (products) {
      if (products.length > 5) {
        this.topSellingItems = products.slice(0, 5)
      } else {
        this.topSellingItems = products.slice(0, products.length)
      }
    },
  },
}
</script>
