<template>
  <div class="dashboard">
    <!-- Header -->
    <h2 class="display-2 text--capitalize">TurtleCreek Store Analytics</h2>
    <p class="text--secondary">turtlecreek-2019/store-analytics/{{ range }}</p>

    <div class="row align--top">
      <div class="flex xs12 md8">

        <!-- Headline Stats -->
        <Headliners
          v-bind:revenue="totalRevenue"
          v-bind:orders="totalOrders">
        </Headliners>

        <!-- Top Products -->
        <TopProducts
          v-bind:allProducts="allProducts">
        </TopProducts>
      </div>

      <!-- Date range selection -->
      <div class="flex xs12 md4">
        <div>
          <va-date-picker
            label="Data Range"
            :config="{mode: 'range', inline: true}"
            v-model="range"
          />
        </div>

        <!-- Top Categories -->
        <TopCategories
          v-bind:allCategories="allCategories">
        </TopCategories>
      </div>
    </div>
  </div>

</template>

<script>
import { timestamp, ordersCollection, productCollection } from '@/firebaseConfig.js'
import Headliners from '@/components_tc/dashboard/Headliners.vue'
import TopProducts from '@/components_tc/dashboard/TopProducts.vue'
import TopCategories from '@/components_tc/dashboard/TopCategories.vue'

export default {
  name: 'dashboard',
  components: {
    Headliners,
    TopProducts,
    TopCategories,
  },
  computed: {
    Orders: function () {
      return this.$store.state.orders
    },
    Products: function () {
      return this.$store.state.products
    },
  },
  data () {
    return {
      orders: [],
      products: [],
      loading: false,
      ordersTally: new Map(),
      productsTally: new Map(),

      startDate: null,
      finishDate: null,
      range: '2020-01-15 to 2020-01-25',

      totalOrders: 0,
      totalRevenue: 0,
      allProducts: [],
      allCategories: [],
    }
  },
  methods: {
    calculateStats: function () {
      const products = Array.from(this.ordersTally.values())
      const categories = Array.from(this.productsTally.values())

      products.sort((a, b) => (a.totalProductSold > b.totalProductSold ? 1 : -1)).reverse()
      categories.sort((a, b) => (a.count > b.count ? 1 : -1)).reverse()

      this.allProducts = products
      this.allCategories = categories
    },

    buildTallySheet: function () {
      const _this = this
      this.totalOrders = 0
      this.totalRevenue = 0
      this.ordersTally = new Map()
      this.productsTally = new Map()

      this.orders = this.orders.filter(order => {
        return order.status !== 'Refunded'
      })

      this.orders.forEach(function (order) {
        if (_this.compareDates(order.date, _this.startDate, _this.finishDate)) {
          _this.tallyTotals(order)
          _this.addToOrderTally(order)
        }
      })
      this.ordersTally.delete('Shipping Cost')
      this.calculateStats()
    },

    tallyTotals: function (order) {
      this.totalOrders += 1
      this.totalRevenue += order.total
    },

    createFirebaseTimestamp: function (date) {
      return timestamp.fromDate(date)
    },

    epochToGMT: function (epoch) {
      return new Date(epoch.seconds * 1000)
    },

    epochToCalenderDate: function (epoch) {
      const date = this.epochToGMT(epoch)
      return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    },

    calenderDateToEpoch: function (datetime) {
      const date = datetime.split('-')
      return this.createFirebaseTimestamp(new Date(date[0], date[1] - 1, date[2]))
    },

    compareDates: function (orderDate, startDate, finishDate) {
      const saleDate = {}
      saleDate.year = parseInt(orderDate.split('/')[2])
      saleDate.month = parseInt(orderDate.split('/')[1])
      saleDate.day = parseInt(orderDate.split('/')[0])

      const saleDateObj = this.createFirebaseTimestamp(new Date(saleDate.year, saleDate.month - 1, saleDate.day))
      return startDate <= saleDateObj && saleDateObj <= finishDate
    },

    addToOrderTally: function (order) {
      const _this = this

      // Check does order contain any items
      if (order.orderedItems) {
        order.orderedItems.forEach(function (item) {
          // Check is item already in tally
          _this.verifyTallyItem(_this.ordersTally, item.name)

          // Check is option already in item.
          const tallyItem = _this.ordersTally.get(item.name)
          _this.verifyItemOption(tallyItem, item.description)

          // Calculate Product Option tally figures
          const itemOption = tallyItem.options.get(item.description)
          const productID = _this.setTallyData(itemOption, order, item)

          // Calaculate Product total tally figures
          tallyItem.totalProductSold += itemOption.totalOptionSold
          tallyItem.totalProductRevenue += itemOption.totalOptionRevenue
          tallyItem.productID =
            (productID == null) ? '' : productID
        })
      }
    },

    verifyTallyItem: function (tally, name) {
      // If no item exists, add it tally
      if (!tally.has(name)) {
        tally.set(name, {
          productName: name,
          totalProductSold: 0,
          totalProductRevenue: 0,
          options: new Map(),
        })
      }
    },

    verifyItemOption: function (tallyItem, option) {
      // If no option exists, add it item
      if (!tallyItem.options.has(option)) {
        tallyItem.options.set(option, {
          totalOptionSold: 0,
          totalOptionRevenue: 0,
          saleDates: new Map(),
        })
      }
    },

    setTallyData: function (tally, order, item) {
      const saleCount = parseInt(item.quantity)
      const saleValue = saleCount * parseFloat(item.price)

      tally.totalOptionSold += saleCount
      tally.totalOptionRevenue += saleValue
      tally.saleDates.set(order.date, {
        quantity: saleCount,
        revenue: saleValue,
      })

      const productInfo = this.setProductInfo(item.name, saleCount, saleValue)
      return productInfo
    },

    setDefaultDates: function () {
      var now = new Date()
      this.startDate = this.finishDate = this.createFirebaseTimestamp(now)
      this.range = this.epochToCalenderDate(this.startDate) + ' to ' + this.epochToCalenderDate(this.finishDate)
    },

    getToday: function () {
      var today = new Date()
      var dd = String(today.getDate())
      var mm = String(today.getMonth() + 1)
      var yyyy = today.getFullYear()
      return dd + '/' + mm + '/' + yyyy
    },

    setProductInfo: function (name, saleCount, saleValue) {
      if (name !== 'Shipping Cost') {
        const info = this.getProductInfo(name)
        if (info) {
          if (!this.productsTally.has(info.category)) {
            this.productsTally.set(info.category, {
              category: info.category,
              count: 0,
              revenue: 0,
            })
          }
          this.productsTally.get(info.category).count += saleCount
          this.productsTally.get(info.category).revenue += saleValue
          return info.id
        } else {
          return null
        }
      } else {
        return null
      }
    },

    getProductInfo: function (productName) {
      for (var product of this.products) {
        if (product.title === productName) {
          return {
            id: product.productID,
            category: product.category,
          }
        }
      } return null
    },

    getOrderData: function (event, resolve, reject) {
      var _this = this
      let newOrders = 0
      let totalRevenue = 0
      let uniqueCustomers = 0
      const seenCustomers = {}
      const today = this.getToday()

      if (this.Orders.ordersData === undefined || this.Orders.ordersData.length === 0 || event != null) {
        return ordersCollection
          .get()
          .then(function (dataSnapshot) {
            var jsonOrders = []

            dataSnapshot.forEach(function (item) {
              var itemData = item.data()

              newOrders += (itemData.date === today) ? 1 : 0
              totalRevenue += (itemData.total) ? parseFloat(itemData.total) : 0
              if (!seenCustomers[itemData.customerID]) {
                uniqueCustomers += 1
                seenCustomers[itemData.customerID] = true
              }

              jsonOrders.push(itemData)
            })

            jsonOrders = jsonOrders.slice(0, jsonOrders.length - 1).sort((a, b) =>
              parseFloat(a.orderID.slice(2)) > parseFloat(b.orderID.slice(2)) ? 1 : -1,
            )
            _this.orders = jsonOrders.reverse()

            var orderStoreCollection = {
              ordersData: _this.orders,
              newOrders: newOrders,
              totalOrders: _this.orders.length,
              uniqueCustomers: uniqueCustomers,
              totalRev: totalRevenue,
            }

            _this.$store.commit('setOrders', orderStoreCollection)
            _this.buildTallySheet()
            return resolve('Finished')
          })
          .catch(err => {
            alert(err.message)
            return reject(err.message)
          })
      } else {
        this.orders = this.Orders.ordersData
        this.buildTallySheet()
        return resolve('Finished')
      }
    },

    getProductData: function (event, resolve, reject) {
      var _this = this

      if (this.Products.productData === undefined || this.Products.productData.length === 0 || event != null) {
        return productCollection
          .get()
          .then(function (dataSnapshot) {
            const jsonProducts = []
            const outOfStockJson = []
            const featuredItems = []

            dataSnapshot.forEach(function (item) {
              var itemData = item.data()

              if (Object.values(itemData.quantity).reduce((a, b) => a + b, 0) <= 0) {
                itemData.availability = 'Out of Stock'
                outOfStockJson.push(itemData)
              }
              if (itemData.status) {
                featuredItems.push(itemData)
              }
              jsonProducts.push(itemData)
            })

            outOfStockJson.sort((a, b) => (a.title > b.title) ? 1 : -1)
            _this.products = jsonProducts.sort((a, b) => (a.title > b.title) ? 1 : -1)
            const productStoreCollection = {
              productData: _this.products,
              outOfStockItems: outOfStockJson,
              featuredItems: featuredItems,
            }

            _this.$store.commit('setProducts', productStoreCollection)
            return resolve('Finished')
          })
          .catch(err => {
            alert(err.message)
            return reject(err.message)
          })
      } else {
        this.products = this.Products.productData
        return resolve('Finished')
      }
    },

    populateData: async function () {
      const _this = this
      const populateOrders = new Promise((resolve, reject) => {
        return _this.getOrderData(null, resolve, reject)
      })

      const populateProducts = new Promise((resolve, reject) => {
        return _this.getProductData(null, resolve, reject)
      })

      await Promise.all([populateOrders, populateProducts])
      this.loading = false
    },
  },
  mounted () {
    this.loading = true
    this.setDefaultDates()
    this.populateData()
  },
  watch: {
    range: function (rangeString) {
      const rangeDates = rangeString.split(' ')
      if (rangeDates.length > 1) {
        this.startDate = this.calenderDateToEpoch(rangeDates[0])
        this.finishDate = this.calenderDateToEpoch(rangeDates[2])
        this.buildTallySheet()
      }
    },
  },
}
</script>
