<template>
  <div class="row align--center">

    <!-- Total Orders -->
    <div class="flex xs12 md4">
      <va-card class="mb-4" color="info">
        <p class="display-2 mb-0" style="color: white;">{{ orders }}</p>
        <p>Total Orders</p>
      </va-card>
    </div>

    <!-- Total Revenue -->
    <div class="flex xs12 md4">
      <va-card class="mb-4" color="info">
        <p class="display-2 mb-0" style="color: white;">€{{ revenue.toFixed(2) }}</p>
        <p>Total Revenue</p>
      </va-card>
    </div>

    <!-- Total Orders -->
    <div class="flex xs12 md4">
      <va-card class="mb-4" color="info">
        <p class="display-2 mb-0" style="color: white;">€{{ (revenue / orders).toFixed(2) }}</p>
        <p>Average Order</p>
      </va-card>
    </div>

  </div>
</template>

<script>

export default {
  name: 'Headliners',
  props: ['revenue', 'orders'],
}
</script>
