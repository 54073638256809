<template>
  <va-card class="d-flex mt-4" >
    <div class="row align--top">
      <div class="flex xs12 md12">

        <!-- Card Title -->
        <h1>Best Selling Categories</h1>

        <!-- Donut Chart -->
        <va-chart
          v-if="categoryData.labels.length != 0"
          class="chart chart--donut"
          :data="categoryData" type="donut"
        />

        <p v-else>
          No Sales Data
        </p>
      </div>
    </div>
  </va-card>
</template>

<script>
export default {
  name: 'TopCategories',
  props: ['allCategories'],
  computed: {
    categoryData: function () {
      const _this = this
      const categoryData = {
        labels: [],
        datasets: [
          {
            label: 'Best Selling Categories (Count)',
            backgroundColor: [],
            data: [],
          },
        ],
      }
      this.allCategories.forEach(function (category) {
        categoryData.labels.push(category.category)
        categoryData.datasets[0].data.push(category.count)
        categoryData.datasets[0].backgroundColor.push(_this.getColors(category.count))
      })
      categoryData.datasets[0].data.slice(0, 10)
      return categoryData
    },
  },

  methods: {
    getColors: function (value) {
      const sold = this.getPercent(value)
      if (sold <= 100 && sold > 80) {
        return '#40e583'
      } else if (sold <= 80 && sold > 60) {
        return '#71baff'
      } else if (sold <= 60 && sold > 40) {
        return '#2c82e0'
      } else if (sold <= 40 && sold > 20) {
        return '#ffc200'
      } else {
        return '#e34b4a'
      }
    },

    getPercent: function (count) {
      return (count / this.allCategories[0].count) * 100
    },

  },
}
</script>

<style>
.chart {
  margin-top: 3vh;
  height: 500px;
}
</style>
